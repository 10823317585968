<template>
  <section>
    <v-container class="fill-height">
      <v-row align="center" justify="center">
        <v-col sm="12" md="7" lg="7" xl="8">
          <v-card>
            <v-card-title class="px-9">
              <v-card class="mt-n9" color="primary" width="100%" elevation="6">
                <v-card-text class="text-center pa-3">
                  <v-img
                    class="mx-auto"
                    width="6rem"
                    contain
                    alt="Allec Logo"
                    transition="scale-transition"
                  />

                  <div class="mt-5">
                    <v-btn
                      v-for="icon in icons"
                      :key="icon"
                      class="mx-4 white--text"
                      icon
                    >
                      <v-icon size="24px">
                        {{ icon }}
                      </v-icon>
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-card-title>
            <v-card-text>
              <register-form></register-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import RegisterForm from "@/components/auth/RegisterForm.vue";
export default {
  data() {
    return {
      icons: ["mdi-facebook", "mdi-google", "mdi-instagram"],
    };
  },
  components: {
    RegisterForm,
  },
};
</script>

<style scoped>
section {
  background: url(https://image.freepik.com/psd-gratis/marketing-digital-conexion-wifi-5g_23-2148386087.jpg)
    no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>