<template>
  <validation-observer ref="register_observer" v-slot="{ invalid }">
    <v-form ref="registerForm" @submit.prevent="validate">
      <v-row>
        <!-- First name -->
        <v-col cols="12" sm="6" md="6">
          <validation-provider
            mode="aggressive"
            v-slot="{ errors }"
            name="First Name"
            rules="required"
          >
            <v-text-field
              :error-messages="errors"
              v-model="formData.firstName"
              label="First Name"
              maxlength="20"
              required
            ></v-text-field>
          </validation-provider>
        </v-col>
        <!-- Last name -->
        <v-col cols="12" sm="6" md="6">
          <validation-provider
            v-slot="{ errors }"
            name="First Name"
            rules="required"
          >
            <v-text-field
              :error-messages="errors"
              v-model="formData.lastName"
              label="Last Name"
              maxlength="20"
              required
            ></v-text-field>
          </validation-provider>
        </v-col>
        <!-- Username -->
        <v-col cols="12">
          <validation-provider
            mode="aggressive"
            v-slot="{ errors }"
            name="Username"
            rules="required|max:16"
          >
            <v-text-field
              v-model="formData.username"
              :counter="16"
              :error-messages="errors"
              label="Username"
              required
            ></v-text-field>
          </validation-provider>
        </v-col>
        <!-- Email -->
        <v-col cols="12">
          <validation-provider
            mode="aggressive"
            v-slot="{ errors }"
            name="Email"
            rules="required|email"
          >
            <v-text-field
              v-model="formData.email"
              :error-messages="errors"
              label="E-mail"
              required
            ></v-text-field>
          </validation-provider>
        </v-col>
        <!-- Password -->
        <v-col cols="12">
          <validation-provider
            mode="aggressive"
            vid="c_password"
            v-slot="{ errors }"
            name="Password"
            :rules="{
              required: true,
              regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
              min: 8,
            }"
          >
            <v-text-field
              :error-messages="errors"
              v-model="formData.password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              name="password"
              label="Password"
              hint="At least 8 characters"
              counter
              @click:append="show1 = !show1"
            ></v-text-field>
          </validation-provider>
        </v-col>
        <!-- Confirm Password -->
        <v-col cols="12">
          <validation-provider
            mode="aggressive"
            v-slot="{ errors }"
            name="Confirm Password"
            :rules="{
              required: true,
              confirmed: 'c_password',
            }"
          >
            <v-text-field
              :error-messages="errors"
              block
              v-model="formData.c_password"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              name="c_password"
              label="Confirm Password"
              counter
              @click:append="show2 = !show2"
            ></v-text-field>
          </validation-provider>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="d-flex mx-auto" cols="12" sm="3" xsm="12">
          <v-btn
            :disabled="invalid || loadings.registerBtn"
            text
            type="submit"
            color="secondary"
            :loading="loadings.registerBtn"
          >
            {{ $t("auth.register.text") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </validation-observer>
</template>

<script>
import {
  required,
  min,
  max,
  regex,
  email,
  confirmed,
} from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
import { mapActions } from "vuex";

setInteractionMode("eager");

extend("confirmed", {
  ...confirmed,
  message: "Passwords must match",
});

extend("email", {
  ...email,
  message: "Email must be valid",
});

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("regex", {
  ...regex,
  message:
    "{_field_} must contains at least: 1 Number, 1 Uppercase letter and 1 Lowercase letter",
});

extend("min", {
  ...min,
  message: "{_field_} must be at least than 8 characters",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});
export default {
  data: () => ({
    loadings: {
      registerBtn: false,
    },
    formData: {
      firstName: "JHonatan",
      lastName: "Avila",
      username: "avilajhonalex",
      email: "jaavila803@gmail.com",
      password: "Avila123",
      c_password: "Avila123",
    },
    show1: false,
    show2: false,
  }),
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  methods: {
    ...mapActions(["signUserUp"]),
    validate() {
      const self = this;
      if (self.$refs.register_observer.validate()) {
        self.loadings.registerBtn = true;
        self
          .signUserUp(self.formData)
          .then((suss) => {
            if (suss.data.token) {
              self.showNotification({
                type: 1,
                text: self.$t("auth.register.sucessMessage"),
                color: "success",
              });
              self.$nextTick(() => {
                self.$refs.registerForm.resetValidation();
                self.$refs.registerForm.reset();
                self.$router.replace({ path: "/" });
              });
            }
          })
          .catch((err) => {
            self.$swal.fire({
              icon: "error",
              title: self.$t("dialogs.titles.error"),
              text: `${self.$t("dialogs.body.error")} ${err.statusText}`,
            });
          })
          .finally(() => {
            self.loadings.registerBtn = false;
          });
      }
    },
    reset() {
      this.$refs.register_observer.reset();
    },
  },
};
</script>

<style>
</style>